/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /*font: inherit;*/
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*
Name:			style_clearfix
Example:		class="clearfix|cl"
Explain:		Clearfix（简写cl）避免因子元素浮动而导致的父元素高度缺失能问题
*/
.cl:after, .clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden
}

.cl, .clearfix {
  zoom: 1
}

/* custom */
a {
  color: #7e8c8d;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

html, body {
  height: 100%;
}

* {
  font-family: "Microsoft YaHei", "Times New Roman", "SimSun", "SimHei", "STSong", "STFangsong", "SimHei", "Hiragino Sans GB";
}

/*::-webkit-scrollbar {*/
/*  width: 5px;*/
/*  height: 5px;*/
/*}*/

/*::-webkit-scrollbar-track-piece {*/
/*  background-color: rgba(241, 46, 46, 0.2);*/
/*  -webkit-border-radius: 6px;*/
/*}*/

/*::-webkit-scrollbar-thumb:vertical {*/
/*  height: 5px;*/
/*  background-color: rgba(125, 125, 125, 0.7);*/
/*  -webkit-border-radius: 6px;*/
/*}*/

/*::-webkit-scrollbar-thumb:horizontal {*/
/*  width: 5px;*/
/*  background-color: rgba(125, 125, 125, 0.7);*/
/*  -webkit-border-radius: 6px;*/
/*}*/

::-webkit-scrollbar {
  /*滚动条整体样式:高宽分别对应横竖滚动条的尺寸*/
  width: .5rem;
  height: .5rem;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  background-color: rgb(126, 121, 239);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3) 10%,
    transparent 10%,
    rgba(255, 255, 255, 0.3) 20%,
    transparent 20%,
    rgba(255, 255, 255, 0.3) 30%,
    transparent 30%,
    rgba(255, 255, 255, 0.3) 40%,
    transparent 40%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 50%,
    rgba(255, 255, 255, 0.3) 60%,
    transparent 60%,
    rgba(255, 255, 255, 0.3) 70%,
    transparent 70%,
    rgba(255, 255, 255, 0.3) 80%,
    transparent 80%,
    rgba(255, 255, 255, 0.3) 90%,
    transparent 90%,
    rgba(255, 255, 255, 0.3) 100%,
    transparent 100%
  );
}

/*::-webkit-scrollbar-track {*/
/*  !*滚动条里面轨道*!*/
/*  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);*/
/*  background: #f5f5f5;*/
/*  border-radius: 10px;*/
/*}*/

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
